import React, { useMemo, useCallback } from 'react'
import Link from 'next/link'
import { Box, Stack, styled, Typography, Divider, Chip } from '@mui/material'
import { Region } from 'types/region'
import { Industry } from 'types/industry'
import { footerMatching as t } from 'public/locales/ja/components/elements/footerMatching'
import { ImageFlux } from 'components/elements/ImageFlux'
import { Prefecture } from 'types/prefecture'

type NavigateItem = {
  title: string
  url: string
}

type FooterMatchingProps = {
  regions: Region[]
  prefectures: Prefecture[]
  industries: Industry[]
  abouts: {
    first: NavigateItem[]
    second: NavigateItem[]
  }
  salesAmountCards: NavigateItem[]
  priceOfProject: NavigateItem[]
  linksConnect?: boolean
}

export const FooterMatching: React.FC<React.PropsWithChildren<FooterMatchingProps>> = ({
  regions,
  prefectures,
  industries,
  abouts,
  priceOfProject,
}) => {
  const termOfServicesAndPrivacy = useMemo<NavigateItem[]>(
    () => [
      {
        title: t.termOfServices,
        url: '/terms',
      },
      {
        title: t.privacyPolicy,
        url: '/privacy',
      },
      {
        title: t.securityPolicy,
        url: '/2021_security_policy.pdf',
      },
      {
        title: t.electronicPublicNotice,
        url: '/public_announcement',
      },
      {
        title: '特定商取引法に基づく表記',
        url: '/tokutei',
      },
      {
        title: '中小M&Aガイドラインへの取り組み',
        url: '/2023_ma_guideline.pdf',
      },
    ],
    []
  )

  const renderIndustriesSearch = useCallback(() => {
    return (
      <SearchAreaContainer direction={'row'}>
        {industries.map((industry, index) => (
          <React.Fragment key={industry.id}>
            <Link href={`/projects?industries=${industry.id}`} passHref legacyBehavior>
              <StyledAnchorTag>
                <SearchText variant={'body2'}>{industry.name}</SearchText>
              </StyledAnchorTag>
            </Link>
            {index !== industries.length - 1 && <StyledVerticalDivider orientation={'vertical'} variant={'middle'} />}
          </React.Fragment>
        ))}
      </SearchAreaContainer>
    )
  }, [industries])

  const renderRegionsSearch = useCallback(() => {
    return (
      <SearchAreaContainer direction={'row'}>
        {regions.map((region, index) => (
          <React.Fragment key={region.id}>
            <Link href={`/projects?regions=${region.id}`} passHref legacyBehavior>
              <StyledAnchorTag>
                <SearchText variant={'body2'}>{region.name}</SearchText>
              </StyledAnchorTag>
            </Link>
            {index !== regions.length - 1 && <StyledVerticalDivider orientation={'vertical'} variant={'middle'} />}
          </React.Fragment>
        ))}
      </SearchAreaContainer>
    )
  }, [regions])

  const renderPrefecturesSearch = useCallback(() => {
    return (
      <SearchAreaContainer direction={'row'}>
        {prefectures?.map((prefecture, index) => (
          <React.Fragment key={prefecture.id}>
            <Link href={`/projects?prefectures=${prefecture.id}`} passHref legacyBehavior>
              <StyledAnchorTag>
                <SearchText variant={'body2'}>{prefecture.name}</SearchText>
              </StyledAnchorTag>
            </Link>
            {index !== prefectures.length - 1 && <StyledVerticalDivider orientation={'vertical'} variant={'middle'} />}
          </React.Fragment>
        ))}
      </SearchAreaContainer>
    )
  }, [prefectures])

  const renderProjectPriceSearch = useCallback(() => {
    return (
      <SearchAreaContainer direction={'row'}>
        {priceOfProject.map((item, index) => (
          <React.Fragment key={item.title}>
            <Link href={item.url} passHref legacyBehavior>
              <StyledAnchorTag>
                <SearchText variant={'body2'}>{item.title}</SearchText>
              </StyledAnchorTag>
            </Link>
            {index !== priceOfProject.length - 1 && (
              <StyledVerticalDivider orientation={'vertical'} variant={'middle'} />
            )}
          </React.Fragment>
        ))}
      </SearchAreaContainer>
    )
  }, [priceOfProject])

  const renderTermOfServicesAndPrivacy = useCallback(() => {
    return (
      <Stack direction={'row'} columnGap={{ xs: 1, md: 2 }} alignItems={'center'}>
        {termOfServicesAndPrivacy.map((item, index) => (
          <React.Fragment key={item.title}>
            <Link href={item.url} passHref legacyBehavior>
              <StyledAnchorTag>
                <SearchText variant={'body2'}>{item.title}</SearchText>
              </StyledAnchorTag>
            </Link>
            {index !== termOfServicesAndPrivacy.length - 1 && (
              <StyledVerticalDivider orientation={'vertical'} variant={'middle'} />
            )}
          </React.Fragment>
        ))}
      </Stack>
    )
  }, [termOfServicesAndPrivacy])

  return (
    <Wrapper>
      <SocialWrapper direction={'row'}>
        <LogoWrapper>
          <Link href={'/'} passHref legacyBehavior>
            <StyledAnchorTag>
              <ImageFlux
                src={'/images/logo_ma_navi.png'}
                alt={'ma_navi_logo'}
                loading={'lazy'}
                width={'188'}
                height={'40'}
              />
            </StyledAnchorTag>
          </Link>
        </LogoWrapper>
        <Stack direction={'row'} alignItems={'center'} columnGap={3}>
          <Link href={'https://twitter.com/ma_navigator'} passHref legacyBehavior>
            <SocialAnchor target={'_blank'}>
              <ImageFlux src={'/images/matching/twitter_gray.png'} alt={'twitter logo'} loading={'lazy'} width={'37'} />
            </SocialAnchor>
          </Link>
          <Link href={'https://www.facebook.com/manavigator.online'} passHref legacyBehavior>
            <SocialAnchor target={'_blank'}>
              <ImageFlux
                src={'/images/matching/facebook_gray.png'}
                alt={'facebook logo'}
                loading={'lazy'}
                width={'37'}
              />
            </SocialAnchor>
          </Link>
          <Link href={'https://page.line.me/lzn3846z?openQrModal=true'} passHref legacyBehavior>
            <SocialAnchor target={'_blank'}>
              <ImageFlux src={'/images/matching/line_gray.png'} alt={'line logo'} loading={'lazy'} width={'37'} />
            </SocialAnchor>
          </Link>
        </Stack>
      </SocialWrapper>
      <NavigationWrapper direction={'row'}>
        <Stack direction={'row'} gap={{ xs: 3, md: 8 }}>
          <Stack gap={2}>
            {abouts.first.slice(0, 3).map((item) => (
              <Link href={item.url} key={item.title} passHref legacyBehavior>
                <StyledAnchorTag>
                  <NavigationText>{item.title}</NavigationText>
                </StyledAnchorTag>
              </Link>
            ))}
          </Stack>
          <Stack gap={2}>
            {abouts.first.slice(3).map((item) => (
              <Link href={item.url} key={item.title} passHref legacyBehavior>
                <StyledAnchorTag>
                  <NavigationText>{item.title}</NavigationText>
                </StyledAnchorTag>
              </Link>
            ))}
          </Stack>
        </Stack>
        <Stack gap={2} pr={2}>
          {abouts.second.map((item) => (
            <Link href={item.url} key={item.title} passHref legacyBehavior>
              <StyledAnchorTag>
                <NavigationText>{item.title}</NavigationText>
              </StyledAnchorTag>
            </Link>
          ))}
        </Stack>
      </NavigationWrapper>
      <DividerWrapper>
        <Divider />
      </DividerWrapper>
      <SearchAreaWrapper direction={'row'}>
        <StyledTag label={t.industries} />
        {renderIndustriesSearch()}
      </SearchAreaWrapper>
      <SearchAreaWrapper direction={'row'}>
        <StyledTag label={t.regions} />
        {renderRegionsSearch()}
      </SearchAreaWrapper>
      <SearchAreaWrapper direction={'row'}>
        <StyledTag label={t.prefectures} />
        {renderPrefecturesSearch()}
      </SearchAreaWrapper>
      <SearchAreaWrapper direction={'row'}>
        <StyledTag label={t.salesPrice} />
        {renderProjectPriceSearch()}
      </SearchAreaWrapper>
      <DividerWrapper>
        <Divider />
      </DividerWrapper>
      <TermAndConditionWrapper>
        {renderTermOfServicesAndPrivacy()}
        <CopyRightText variant={'body2'}>{t.copyRight}</CopyRightText>
      </TermAndConditionWrapper>
    </Wrapper>
  )
}

const Wrapper = styled(Box)(
  ({ theme }) => `
  max-width: 1144px;
  margin: 0 auto;
  padding-top: ${theme.spacing(12)};
  padding-bottom: ${theme.spacing(8)};
  ${theme.breakpoints.down('lg')} {
    padding-left: ${theme.spacing(2)};
    padding-right: ${theme.spacing(2)};
  }
  ${theme.breakpoints.down('sm')} {
    padding-top: ${theme.spacing(4)};
    padding-bottom: ${theme.spacing(8)};
  }
`
)

const SocialWrapper = styled(Stack)(
  ({ theme }) => `
  padding-bottom: ${theme.spacing(10)};
  align-items: center;
  justify-content: space-between;
  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
    row-gap: ${theme.spacing(3)};
    padding-bottom: ${theme.spacing(4)};
  }
`
)

const LogoWrapper = styled(Box)(
  () => `
  max-width: 188px;
`
)

const NavigationWrapper = styled(Stack)(
  ({ theme }) => `
  padding-bottom: ${theme.spacing(8)};
  justify-content: space-between;
  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
    row-gap: ${theme.spacing(6)};
    padding-bottom: ${theme.spacing(4)};
  }
`
)

const NavigationText = styled(Typography)(
  ({ theme }) => `
  font-weight: 700;
  ${theme.breakpoints.down('sm')} {
    font-size: 16px;
  }
`
)

const DividerWrapper = styled(Box)(
  ({ theme }) => `
  padding-bottom: ${theme.spacing(5)};
  ${theme.breakpoints.down('sm')} {
    padding-bottom: ${theme.spacing(3)};
  }
`
)

const StyledTag = styled(Chip)(
  ({ theme }) => `
  min-width: 84px;
  font-size: 16px;
  font-weight: 700;
  border-radius: ${theme.spacing(0.5)};
  padding: ${theme.spacing(0.5)} 0;
  color: ${theme.palette.primary.main};
  background: ${theme.blue.base};
  align-self: flex-start;
`
)

const SearchAreaWrapper = styled(Stack)(
  ({ theme }) => `
  padding-bottom: ${theme.spacing(5)};
  column-gap: ${theme.spacing(2)};
  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
    row-gap: ${theme.spacing(1)};
    padding-bottom: ${theme.spacing(2)};
  }
`
)

const StyledVerticalDivider = styled(Divider)(
  ({ theme }) => `
  height: ${theme.spacing(2)};
  margin: 0;
`
)

const SearchAreaContainer = styled(Stack)(
  ({ theme }) => `
  column-gap: ${theme.spacing(2)};
  row-gap: ${theme.spacing(1.5)};
  flex-wrap: wrap;
  align-items: center;
  ${theme.breakpoints.down('sm')} {
    column-gap: ${theme.spacing(1)};
    row-gap: ${theme.spacing(1)};
  }
`
)

const TermAndConditionWrapper = styled(Box)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${theme.breakpoints.down('md')} {
    padding-top: ${theme.spacing(2)};
    row-gap: ${theme.spacing(2)};
    flex-direction: column;
  }
`
)

const SearchText = styled(Typography)(
  ({ theme }) => `
  font-size: 14px;
  cursor: pointer;
  ${theme.breakpoints.down('sm')} {
    font-size: 12px;
  }
`
)
const CopyRightText = styled(SearchText)(
  () => `
    cursor: default;
  `
)

const StyledAnchorTag = styled('a')(
  ({ theme }) => `
  text-decoration: none;
  color: ${theme.tertiary.darkness3};
`
)
const SocialAnchor = styled(StyledAnchorTag)(
  () => `
  width: 37px;
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  `
)
