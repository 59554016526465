import React from 'react'
import { getImageFluxUrlFor, ASPECT_MODE } from 'utils/imageFlux'

/**
 * We use ImageFlux for image optimization and caching
 * https://www.sakura.ad.jp/services/imageflux/image/
 * Use this for user uploaded images. Static images can be optimized with next/image
 */
type WidthOrHeightRequired = { width: string; height?: string } | { width?: string; height: string }
type Props = {
  src: string
  alt: string
  loading?: 'eager' | 'lazy'
  className?: string
  onError?: () => void
  aspectMode?: keyof typeof ASPECT_MODE
  irAuto?: boolean
} & WidthOrHeightRequired
export const ImageFlux: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const options = {
    loading: props.loading || 'lazy',
    width: props.width,
    height: props.height,
    className: props.className,
    onError: props.onError,
  }
  const src = getImageFluxUrlFor(
    props.src,
    {
      width: props.width,
      height: props.height,
      aspectMode: props.aspectMode,
      dpr: 1,
    },
    props.irAuto
  )
  const hiResSrc = getImageFluxUrlFor(
    props.src,
    {
      width: props.width,
      height: props.height,
      aspectMode: props.aspectMode,
      dpr: 2,
    },
    props.irAuto
  )
  // eslint-disable-next-line @next/next/no-img-element
  return (
    <picture>
      <source srcSet={`${src} 1x, ${hiResSrc} 2x`} />
      <img {...options} src={src} alt={props.alt} />
    </picture>
  )
}
