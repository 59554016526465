export const newRegistrationSection = {
  title: 'M&Aナビはすべての経営に最良の選択を提供します',
  description:
    '売り手の方にとっても買い手の方にとっても、\n未来を切り拓く取引を実現したい。\nM&Aナビは両者が同じ目線で対話をできる\n「フラットなプラットフォーム」を目指しています。',
  button: {
    freeTry: '会員登録（無料）',
    emailRegistration: 'メールマガジン登録',
  },
  registration: {
    title: 'メールマガジンでは、\n新着情報が受け取れます',
    notice: '必要な条件を設定するだけ！',
  },
}
