import map from 'lodash/map'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'
import { config } from 'config'

const URL_DOMAIN_REGEX = /^https:\/\/.*ma-navigator.com(.*)/
export const ASPECT_MODE = {
  scale: 0,
  forceScale: 1,
  crop: 2,
  pad: 3,
}

/**
 * We use ImageFlux for image optimization and caching
 * https://www.sakura.ad.jp/services/imageflux/image/
 * Use this for user uploaded images. Static images can be optimized with next/image
 */
export function getImageFluxUrlFor(
  src: string,
  sizeParams?: { width?: string; height?: string; aspectMode?: keyof typeof ASPECT_MODE; dpr?: number },
  irAuto = false
): string {
  // if src starts with 'https://ma-navigator.com/', remove this from src.
  const regexUrl = URL_DOMAIN_REGEX.exec(src)
  const path = regexUrl && regexUrl.length > 0 ? regexUrl[1] : src

  const aspectMode = sizeParams?.aspectMode || 'crop'
  if (config.imageFluxHost) {
    const formatParams = { f: 'webp:auto' }
    const sizeFormat = sizeParams
      ? { h: sizeParams.height, w: sizeParams.width, a: ASPECT_MODE[aspectMode], dpr: sizeParams.dpr }
      : {}
    const ir = irAuto ? 'auto' : '1'
    const format = `/${map(
      { ...formatParams, ir, ...omitBy(sizeFormat, isUndefined) },
      (val, key) => `${key}=${val}`
    ).join(',')}`
    return `https://${config.imageFluxHost}${format}${path}`
  }

  if (config.host === 'localhost') {
    return src
  }

  return `https://${config.host}${path}`
}
