import { Project } from './project'
import { User } from './user'

interface Chat {
  id: number
  offerId: number
  projectId: number
  fromId: number
  toId: number
  content: string
  createdAt: string
  readAt: string | null
}

export interface AdminChat extends Chat {
  projects: Project
  fromUser: User
  toUser: User
  chatThread: ChatThreadResponse
  chatAttachments: {
    buyerFile: {
      blob: Attachment
    }
    buyerFileId: number
    projectFile: {
      blob: Attachment
    }
    projectFileId: number
  }[]
}

export interface ChatThreadResponse {
  id: number
  stepId: number | null
  offerId: number
  phaseId: number | null
  topicId: number | null
  isOriginal: boolean
  unread: boolean
  title: string | null
  updatedAt: Date
  createdAt: Date
  lastMessage?: string | null
  uncompleted: boolean
  description?: string
  userId: number
  user: User
}

export type ChatThreadFilter = {
  unread?: boolean
  uncompleted?: boolean
  threadId?: string
}

export enum Position {
  LEFT = 'left',
  RIGHT = 'right',
}

export type ChatType = 'text' | 'file' | 'image'

export type Attachment = {
  id: number
  fileId: number
  filename: string
  contentType: string
  byteSize: number
  isProjectFile: boolean
  isUnshared?: boolean
}

type ChatSystemMessage = {
  id: number
  content: string
  createdAt: Date
}

export type MessageChat = {
  chatAttachments?: Attachment[]
  chatSystemMessages?: ChatSystemMessage[]
  content: string
  createdAt: Date
  fromId?: number
  fromUser: User | null
  id: number
  offerId?: number
  projectId?: number
  readAt?: Date
  read?: boolean
  toId?: number
  toUser?: User
  position?: Position
  type: ChatType
  threadTitle?: string
  onDownloadFile?: (file: Attachment) => Promise<void>
}
