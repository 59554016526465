import React, { useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/material'
import axios from 'axios'
import { Industry } from 'types/industry'
import { Region } from 'types/region'
import { HeaderMatching } from 'components/modules/HeaderMatching'
import { FooterMatching } from 'components/elements/FooterMatching'
import { CorporateInformationSection } from 'components/elements/CorporateInformationSection'
import { NewRegistrationSection } from 'components/elements/NewRegistrationSection'
import { useAuth } from 'context/authContext'
import { UserRole } from 'types/user'
import { abouts, priceOfProject, salesAmountCards } from 'utils/metadata'
import { Project } from 'types/project'
import { Prefecture } from 'types/prefecture'

type MatchingLayoutProps = {
  children: React.ReactNode
  isHiddenCorp?: boolean
  industries?: Industry[]
  regions?: Region[]
  prefectures?: Prefecture[]
  hiddenRegistration?: boolean
  isNaviHidden?: boolean
}

export const MatchingLayout: React.FC<React.PropsWithChildren<MatchingLayoutProps>> = ({
  children,
  isHiddenCorp,
  industries,
  regions,
  prefectures,
  hiddenRegistration,
  isNaviHidden = false,
}) => {
  const [sellerProjectStatus, setSellerProjectStatus] = useState('')
  const { user } = useAuth()

  useEffect(() => {
    if (user?.role !== UserRole.Seller) return
    axios.get<Project>('/api/projects/seller').then((res) => setSellerProjectStatus(String(res.data.status || '')))
  }, [user?.role])

  const userRole = useMemo(() => {
    if (user?.role === UserRole.Seller) return UserRole.Seller
    if (user?.role === UserRole.Buyer) return UserRole.Buyer
    if (user?.role === UserRole.Coordinator) return UserRole.Coordinator
    return null
  }, [user?.role])

  return (
    <>
      {!isNaviHidden && <HeaderMatching role={userRole} sellerProjectStatus={sellerProjectStatus} />}
      <Box mt={isNaviHidden ? 0 : 10}>{children}</Box>
      {!isHiddenCorp && <CorporateInformationSection />}
      {!hiddenRegistration && <NewRegistrationSection hideButton={!!user} />}
      <FooterMatching
        regions={regions || []}
        prefectures={prefectures || []}
        industries={industries || []}
        abouts={abouts}
        salesAmountCards={salesAmountCards}
        priceOfProject={priceOfProject}
      />
    </>
  )
}
