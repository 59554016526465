export const menu = {
  mypage: {
    title: 'マイページ',
    titleMenu: 'メニュー',
    items: {
      dashBoard: {
        title: 'ホーム',
      },
      webchat: {
        title: '事務局メッセージ',
      },
      favorite: {
        title: 'お気に入り',
      },
      offer: {
        title: '案件管理',
      },
      project: {
        title: '案件検索',
      },
      setting: {
        title: '設定',
      },
      home: {
        title: 'ホーム',
      },
      deal: {
        title: '商談一覧',
      },
      publish: {
        title: '掲載管理',
      },
      chat: {
        title: '事務局',
        title2: 'チャット',
      },
      projectManagement: {
        title: '案件管理',
        subItems: {
          project: {
            title: '案件管理',
          },
          condition: {
            title: '売却条件',
          },
          company: {
            title: '会社概要',
          },
          finance: {
            title: '財務情報',
          },
        },
      },
      offers: {
        title: '交渉リクエスト管理',
      },
      profile: {
        title: 'プロフィール',
        registration: 'プロフィール登録',
      },
      qas: {
        title: 'Q&A管理',
      },
      storage: {
        title: 'ファイルストレージ',
      },
      settings: {
        title: '設定',
      },
      supportDestination: {
        title: '支援先管理',
      },
      company: {
        title: '会社概要',
      },
      uploadIdentity: {
        title: '本人確認書類アップロード',
      },
    },
    naviItems: {
      coordinators: {
        title: '支援専門家一覧',
      },
      projects: {
        title: '案件一覧',
      },
      columns: {
        title: 'コラム',
      },
      notices: {
        title: 'お知らせ',
      },
      corp: {
        title: '運営会社',
      },
    },
  },
  mainMenu: {
    title: 'メインメニュー',
    items: {
      top: {
        title: 'M&Aナビトップ',
      },
      column: {
        title: 'コラム',
      },
      glossaries: {
        title: '用語集',
      },
    },
  },
  admin: {
    title: 'Admin',
    logout: 'ログアウト',
    items: {
      dashBoard: {
        title: 'Dashboard',
      },
      analytics: {
        title: 'アナリティクス',
      },
      admins: {
        title: '管理者管理',
      },
      users: {
        title: 'ユーザー管理',
      },
      emailUsers: {
        title: 'メールユーザー管理',
      },
      companies: {
        title: '会社管理',
      },
      projects: {
        title: '案件管理',
      },
      contacts: {
        title: 'お問い合わせ管理',
      },
      chats: {
        title: 'チャット管理',
      },
      qas: {
        title: 'Q&A管理',
      },
      industries: {
        title: 'サジェスト管理',
      },
      webchatThreads: {
        title: '事務局メッセージ管理',
      },
      featureArticles: {
        title: '特集管理',
      },
      glossary: {
        title: '用語管理',
        subItems: {
          glossaryCategories: {
            title: 'カテゴリー管理',
          },
          glossaries: {
            title: '用語管理',
          },
        },
      },
    },
  },
  others: {
    title: 'その他',
    items: {
      terms: {
        title: '利用規約',
      },
      privacy: {
        title: '個人情報保護方針',
      },
      corp: {
        title: '運営会社',
      },
      contacts: {
        title: 'お問い合わせ',
      },
    },
  },
}
