import React from 'react'
import Link from 'next/link'
import { Box, styled, Typography, Stack, Button } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { newRegistrationSection as t } from 'public/locales/ja/components/elements/newRegistrationSection'
import { ImageFlux } from 'components/elements/ImageFlux'
import { MobileOnly } from 'components/elements/MobileOnly'
import { DesktopOnly } from 'components/elements/DesktopOnly'

type NewRegistrationSectionProps = {
  hideButton?: boolean
}

export const NewRegistrationSection: React.FC<React.PropsWithChildren<NewRegistrationSectionProps>> = ({
  hideButton = false,
}) => {
  return (
    <Box>
      <OfferWrapper>
        <OfferContainer>
          <OfferTitle align={'center'} variant={'h2'}>
            {t.title}
          </OfferTitle>
          <OfferContentWrapper direction={'row'}>
            <MobileOnly>
              <ImageContainer>
                <ImageFlux
                  src={'/images/matching/new_registration.png'}
                  loading={'lazy'}
                  width={'358'}
                  height={'233'}
                  alt="new_registration"
                />
              </ImageContainer>
            </MobileOnly>
            <DesktopOnly>
              <ImageContainer>
                <ImageFlux
                  src={'/images/matching/new_registration.png'}
                  loading={'lazy'}
                  width={'597'}
                  height={'389'}
                  alt="new_registration"
                />
              </ImageContainer>
            </DesktopOnly>
            <Stack paddingTop={{ xs: 0, md: 1.5 }} rowGap={{ xs: 4, md: 6.5 }}>
              <Description>{t.description}</Description>
              {!hideButton && (
                <Link href={'/users/sign_up'} passHref legacyBehavior>
                  <FreeTryButton variant={'outlined'}>
                    <Typography fontWeight={'bold'}>{t.button.freeTry}</Typography>
                  </FreeTryButton>
                </Link>
              )}
            </Stack>
          </OfferContentWrapper>
        </OfferContainer>
      </OfferWrapper>
      <RegistrationWrapper>
        <RegistrationContainer>
          <RegistrationContentWrapper direction={'row'}>
            <Box>
              <RegistrationTitle variant={'h3'} gutterBottom>
                {t.registration.title}
              </RegistrationTitle>
              <Typography variant={'body1'}>{t.registration.notice}</Typography>
            </Box>
            <Link href={'/buyer/email_sign_up'} passHref legacyBehavior>
              <EmailRegistrationButton variant={'outlined'} endIcon={<ChevronRightIcon />}>
                <Typography fontWeight={'bold'}>{t.button.emailRegistration}</Typography>
              </EmailRegistrationButton>
            </Link>
          </RegistrationContentWrapper>
        </RegistrationContainer>
      </RegistrationWrapper>
    </Box>
  )
}

const OfferWrapper = styled(Box)(
  ({ theme }) => `
  background-image: url('/images/background-circle.svg');
  background-position: center;
  background-size: cover;
  background-color: ${theme.primary.base};
`
)

const RegistrationWrapper = styled(Box)(
  ({ theme }) => `
  background-image: url('/images/matching/air_plane.png');
  background-position: 57% -40%;
  background-size: 185px 205px;
  background-repeat: no-repeat;
  background-color: ${theme.primary.darkness1};
  ${theme.breakpoints.down('md')} {
    background-image: none;
  }
`
)

const Container = styled(Box)(
  ({ theme }) => `
  max-width: 1080px;
  margin: 0 auto;
  color: ${theme.palette.common.white};
  ${theme.breakpoints.down('lg')} {
    padding-left: ${theme.spacing(2)};
    padding-right: ${theme.spacing(2)};
  }
`
)

const OfferContainer = styled(Container)(
  ({ theme }) => `
  padding-top: ${theme.spacing(7)};
`
)

const RegistrationContainer = styled(Container)(
  ({ theme }) => `
  padding-top: ${theme.spacing(4.5)};
  padding-bottom: ${theme.spacing(4.5)};
`
)

const OfferContentWrapper = styled(Stack)(
  ({ theme }) => `
  column-gap: ${theme.spacing(6)};
  ${theme.breakpoints.down('md')} {
    flex-direction: column-reverse;
    row-gap: ${theme.spacing(5.5)};
  }
`
)

const RegistrationContentWrapper = styled(Stack)(
  ({ theme }) => `
  justify-content: space-between;
  align-items: center;
  ${theme.breakpoints.down('md')} {
    row-gap: ${theme.spacing(4)};
    flex-direction: column;
    text-align: center;
  }
`
)

const OfferTitle = styled(Typography)(
  ({ theme }) => `
  font-size: 40px;
  padding-bottom: ${theme.spacing(4)};
  ${theme.breakpoints.down('md')} {
    font-size: 32px;
  }
  ${theme.breakpoints.down('sm')} {
    font-size: 24px;
  }
`
)

const Description = styled(Typography)(
  ({ theme }) => `
  font-size: 20px;
  line-height: 175%;
  ${theme.breakpoints.down('md')} {
    font-size: 18px;
  }
  ${theme.breakpoints.down('sm')} {
    font-size: 16px;
  }
`
)

const StyledButton = styled(Button)(
  ({ theme }) => `
  &:hover {
    background: ${theme.primary.lightness4};
  }
`
)

const FreeTryButton = styled(StyledButton)(
  ({ theme }) => `
  max-width: 325px;
  width: 100%;
  padding: ${theme.spacing(4.5)} 0;
  ${theme.breakpoints.down('md')} {
    align-self: center;
  }
`
)

const EmailRegistrationButton = styled(StyledButton)(
  ({ theme }) => `
  padding: ${theme.spacing(2)} ${theme.spacing(4)};
  ${theme.breakpoints.down('sm')} {
    padding: ${theme.spacing(2)} ${theme.spacing(3)};
  }
`
)

const ImageContainer = styled(Box)(
  ({ theme }) => `
  max-width: 640px;
  img {
    object-fit: cover;
  }
  ${theme.breakpoints.down('md')} {
    width: 100%;
    max-width: unset;
    align-self: center;
    text-align: center
  }
`
)

const RegistrationTitle = styled(Typography)(
  ({ theme }) => `
  ${theme.breakpoints.down('sm')} {
    white-space: pre-line;
  }
`
)
