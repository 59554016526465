import { metaData as t } from 'public/locales/ja/metaData'
import { theme } from 'styles/theme'

export const salesAmountCards = [
  {
    title: '1,000万円',
    url: '/projects?rangeAmountOfSales=0..9999',
  },
  {
    title: '1,000～5,000万円',
    url: '/projects?rangeAmountOfSales=10000..50000',
  },
  {
    title: '5,000万～1億円',
    url: '/projects?rangeAmountOfSales=50000..100000',
  },
  {
    title: '1億円以上',
    url: '/projects?rangeAmountOfSales=100001..1000000000',
  },
]
export const priceOfProject = [
  {
    title: '100万円以下',
    url: '/projects?rangePriceOfProject=0..100',
  },
  {
    title: '100〜300万円',
    url: '/projects?rangePriceOfProject=100..300',
  },
  {
    title: '300〜500万円',
    url: '/projects?rangePriceOfProject=300..500',
  },
  {
    title: '500～750万円',
    url: '/projects?rangePriceOfProject=500..750',
  },
  {
    title: '750〜1,000万円',
    url: '/projects?rangePriceOfProject=750..1000',
  },
  {
    title: '1,000～2,000万円',
    url: '/projects?rangePriceOfProject=1000..2000',
  },
  {
    title: '2,000～3,000万円',
    url: '/projects?rangePriceOfProject=2000..3000',
  },
  {
    title: '3,000～5,000万円',
    url: '/projects?rangePriceOfProject=3000..5000',
  },
  {
    title: '5,000～7,500万円',
    url: '/projects?rangePriceOfProject=5000..7500',
  },
  {
    title: '7,500～1億円',
    url: '/projects?rangePriceOfProject=7500..10000',
  },
  {
    title: '1億～2.5億円',
    url: '/projects?rangePriceOfProject=10000..25000',
  },
  {
    title: '2.5億～5億円',
    url: '/projects?rangePriceOfProject=25000..50000',
  },
  {
    title: '5億～10億円',
    url: '/projects?rangePriceOfProject=50000..100000',
  },
  {
    title: '10億円以上',
    url: '/projects?rangePriceOfProject=100000..10000000',
  },
]
export const abouts = {
  first: [
    {
      title: t.about.seller,
      url: '/about/seller',
    },
    {
      title: t.about.buyer,
      url: '/about/buyer',
    },
    {
      title: t.about.maNavi,
      url: '/about',
    },
    {
      title: t.projects,
      url: '/projects',
    },
    {
      title: t.featureList,
      url: '/features',
    },
    {
      title: t.supportSpecialists,
      url: '/users/coordinators',
    },
    {
      title: t.projectsHistory,
      url: '/recent_viewed_projects',
    },
    {
      title: t.columns,
      url: 'https://ma-navigator.com/columns',
    },
    {
      title: t.notices,
      url: '/notices',
    },
  ],
  second: [
    {
      title: t.faq,
      url: '/faq',
    },
    {
      title: t.glossaries,
      url: '/glossaries',
    },
    {
      title: t.contact,
      url: '/contacts',
    },
    {
      title: t.corp,
      url: '/corp',
    },
  ],
}

export const aboutFirst = {
  menuItems: [
    { menu: t.termOfServices, link: '/terms' },
    { menu: t.informationProtection, link: '/test' },
  ],
  menuTextColor: theme.pink.base,
  menuBgColor: theme.white.base,
}

export const aboutSecond = {
  copyrightText: t.copyrightText,
  copyrightColor: theme.white.base,
  copyrightBgColor: theme.pink.base,
}
