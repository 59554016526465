export const project = {
  projectTitle: {
    title: '案件情報',
    example: '記入例：\n＜フィリピンのオフショア事業＞10名以上のエンジニアが在籍するWEB制作事業の事業譲渡',
  },
  image: 'イメージ画像',
  feature: {
    title: '特徴（強み・PR）',
    desc: '全体に公開されてる情報です。企業名が特定できないような内容にとどめましょう。',
    example:
      '記入例：\n以下の流れでストーリーを書いてみてください。\n① 社名をふせて、事業を簡潔に紹介\n② 事業の強み\n③ 売却の背景\n④ 最後にひとこと',
  },
  notice: {
    title: '申し送り事項',
    desc: '交渉リクエストを承諾した買い手しか見れない情報になるので、 社名など、詳しい情報を入れてください。',
    example:
      '記入例：\n以下の流れでストーリーを書いてみてください。\n① 社名を含めた会社・事業紹介\n② 現在の社員の構成\n③ 譲渡内容について\n④ その他注意事項',
  },
  ogpImg: {
    label: 'イメージ画像',
    selectAttractiveImage: '案件情報に表示される画像です。案件の魅力が分かる画像を設定しましょう。',
    selectImageFiles: '※10MB以下の JPG、PNGファイルを選択してください。',
    selectImage: 'イメージ画像をアップロードする',
    note: '案件情報に表示される画像です。案件の魅力が分かる画像を設定しましょう。\n※10MB以下の JPG、PNGファイルを選択してください。\n※会社やブランドが特定されないような画像（フリー素材など）を選択してください。\n※画像をアップロードしない場合、自動的に左のテンプレート画像が挿入されます。',
  },
  name: '案件名',
  thumbnail: '案件サムネイル',
  thumbnailDesc: '案件情報に表示される画像です。案件の魅力が分かる画像を設定しましょう。',
  NoThumbnail: 'まだ案件サムネイルがアップロードされていません',
  status: {
    label: '掲載ステータス設定',
    infoMemo: '情報開示中',
    '0': '下書き',
    '1': '公開依頼中',
    '2': '修正依頼中',
    '3': '公開中',
    '4': '交渉リクエスト制限中',
    '5': '成約済み',
    '9': '公開停止中',
  },
  pickup: {
    label: 'ピックアップ設定',
    notSet: '未設定',
    0: 'しない',
    1: 'する',
  },
  publishRange: '案件掲載範囲',
}
