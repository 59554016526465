import { useCallback, useEffect, useState, Dispatch, SetStateAction } from 'react'
import axios from 'axios'
import { useLoading } from 'context/loadingContext'
import { Position, MessageChat, Attachment } from 'types/chat'
import { useAuth } from 'context/authContext'
import { useAsyncCallback } from 'hooks/useAsyncCallback'
import { useAxios } from 'context/axiosContext'

type WebChatAttachment = {
  id: number
  blob: Attachment
}

type UseWebchatResponse = {
  messages: MessageChat[]
  isRead?: boolean
  isOpenUploadFile: boolean
  files: File[]
  setOpenUploadFile: Dispatch<SetStateAction<boolean>>
  handleReadWebChat?: () => Promise<void>
  handleSendMessage: (message: string) => Promise<void>
  handleUploadFiles: (files: File[]) => void
  handleDownloadFile: (file: Attachment) => Promise<void>
}

type WebchatResponse = {
  id: number
  webChatAttachments: WebChatAttachment[]
  position: Position
  type: 'text'
  text: string
  createdAt: Date
}

export const useWebchat = (): UseWebchatResponse => {
  const { user } = useAuth()
  const { axiosNone } = useAxios()
  const [messages, setMessages] = useState<MessageChat[]>([])
  const [isSending, setSending] = useState<boolean>(false)
  const [isRead, setIsRead] = useState<boolean>(true)
  const [isOpenUploadFile, setOpenUploadFile] = useState<boolean>(false)
  const [files, setFiles] = useState<File[]>([])
  const { setLoading } = useLoading()

  useEffect(() => {
    fetchWebChat()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkUnReadMessage = useCallback((messages: { position: Position; read: boolean }[]): boolean => {
    const list = messages.filter((item) => item.position === Position.LEFT && !item.read)
    return list.length > 0
  }, [])

  const handleUploadFiles = useCallback((files: File[]) => {
    if (!files?.length) return
    setFiles(files)
  }, [])

  const handleReadWebChat = useAsyncCallback(async () => {
    await axios.patch('/api/web-chats/read')
  }, [])

  const handleDownloadFile = useAsyncCallback(async (file: Attachment) => {
    if (!file) return
    const api = `/api/web-chats/files/${file.id}/download`
    const fileElement = document.createElement('a')
    fileElement.href = api
    fileElement.setAttribute('download', file.filename)
    fileElement.click()
  }, [])

  const fetchWebChat = useCallback(async () => {
    setLoading('progress')
    const { data } = await axios.get('/api/web-chats')
    const chatMessages = data.map((message: WebchatResponse) => ({
      ...message,
      chatAttachments: message.webChatAttachments?.map((item) => ({ ...item.blob, ...item })),
      content: message.text,
      fromUser: message.position === 'right' ? user : null,
    }))
    setMessages(chatMessages)
    setIsRead(!checkUnReadMessage(chatMessages))
    setLoading(false)
  }, [checkUnReadMessage, setLoading, user])

  const handleSendMessage = useCallback(
    async (message?: string, files?: File[]) => {
      try {
        if ((!message && !files?.length) || isSending) return
        setSending(true)

        let blobIds: number[] = []

        if (files?.length) {
          const formData = new FormData()
          files.forEach((file) => {
            formData.append('files', file)
          })
          const { data } = await axiosNone.post('/api/webchat-threads/upload', formData)
          blobIds = data.map((item: Attachment) => item.id)
        }

        await axiosNone.post('/api/web-chats', {
          content: message?.trim(),
          blobIds,
        })

        await fetchWebChat()
      } catch (error) {
        setSending(false)
        throw new Error(error as string)
      }
    },
    [axiosNone, fetchWebChat, isSending]
  )

  return {
    messages,
    handleSendMessage,
    handleUploadFiles,
    isRead,
    handleReadWebChat,
    isOpenUploadFile,
    setOpenUploadFile,
    files,
    handleDownloadFile,
  }
}
