/**
 * DEPRECATED
 * Please use context/axiosProvider instead to show common loading effect and error handling
 * https://github.com/ma-navi/ma_navi/pull/7918
 */
import { useCallback } from 'react'
import axios from 'axios'
import { common as t } from 'public/locales/ja/common'
import { useLoading } from 'context/loadingContext'
import { useFlash } from 'context/flashContext'
import { sendSentryLog } from 'lib/sentry'

const TOKEN_EXPIRED_TEXT = 'Token is expired'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useAsyncCallback<T extends (...args: any[]) => Promise<any>, P = any, R = any>(
  cb: T,
  deps: unknown[]
): (...args: P[]) => Promise<R> {
  const { setFlash } = useFlash()
  const { setLoading } = useLoading()
  return useCallback(
    (...args: P[]): Promise<R> => {
      setLoading('mask')
      return cb(...args)
        .catch((e: Error) => {
          sendSentryLog(e)
          if (axios.isAxiosError(e) && e.response && e.response.status === 401) {
            setFlash({ type: 'error', message: '', isTimeout: true })
            return
          }

          // パスワード設定時に有効期限が切れていた時の処理
          if (
            axios.isAxiosError(e) &&
            e.response &&
            e.response.status === 400 &&
            e.response.data === TOKEN_EXPIRED_TEXT
          ) {
            setFlash({ type: 'error', message: '有効期限が切れています。管理者にお問合せください。' })
            return
          }

          setFlash({ type: 'error', message: t.error.serverError })
        })
        .finally(() => setLoading(false))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...deps, setFlash, setLoading, cb]
  )
}
